import {BaseQueryFn} from '@reduxjs/toolkit/query';
import {BaseQueryApi, createApi, FetchArgs, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BaseQueryExtraOptions} from '@reduxjs/toolkit/src/query/baseQueryTypes';
import {AxiosResponse} from 'axios';
import {v4 as uuidv4} from 'uuid';

import {fetchAuthToken} from '@/src/api/auth';
import {CountryInfo, getCountriesInfo, getUserData as getUserDataReq} from '@/src/api/authService';
import {
  fetchLanguages,
  fetchPriceConfig,
  fetchTargetLanguages,
  fetchTopics,
  getIsPrePostProcessingSettings,
  getRegistryDynamicProjects,
} from '@/src/api/wizard';
import {OptionsProps} from '@/src/components/BaseComponents/BaseSingleSelect';
import {
  ILanguagesResult,
  IPrePostProcessingSettings,
  IPriceConfig,
  IRegistryDynamicProject,
  LanguageSetResponse,
  SetMutationObject,
  TargetLanguage,
} from '@/src/components/Wizard/WizardViewUtils';
import {UnreadNotification} from '@/src/interfaces/types/blendTalk.types';
import {UserData} from '@/src/interfaces/types/user.types';
import {RootState} from '@/src/lib/store/store';
import {ProfileData, setProfileData} from '@/src/lib/store/stores/profile';
import {ConfigDataFromGet, SessionDataFromGet, WizardProjectType} from '@/src/types/Wizard/types';

import {ILanguageSet, ILanguageSetResponse, setLanguagesSets} from './wizard';

const customBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<BaseQueryFn>
) => {
  let headers = {};

  if (typeof window === 'undefined') {
    const {cookies} = await import('next/headers');
    const cookieStore = cookies();
    const sessionId = cookieStore.get('OHTSESS')?.value;
    const JWTCookie = cookieStore.get('JWT')?.value;
    let accessToken: string | null = null;

    if (JWTCookie) {
      try {
        accessToken = JSON.parse(JWTCookie ?? '')?.jwt_token;
      } catch (error) {
        console.error('Error parsing JWT cookie:', error);
        throw new Error('Invalid JWT format');
      }
    }

    headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Cookie: `OHTSESS=${sessionId}`,
    };
  }

  // Return the modified request with dynamic headers
  return fetchBaseQuery({
    baseUrl: process.env.FRONTEND_API_BASE_URL,
    prepareHeaders: (existingHeaders) => {
      if (headers) {
        for (const key in headers) {
          existingHeaders.set(key, headers[key]);
        }
      }
      return existingHeaders;
    },
  })(args, api, extraOptions);
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigDataFromGet['Config'], void>({
      query: () => ({
        url: '/bff/config',
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getBlendTalkFeature: builder.query<boolean, {CSRFToken: string}>({
      query: ({CSRFToken}) => ({
        url: '/bff/feature',
        params: {CSRFToken},
        headers: {'X-CSRF-Token': CSRFToken},
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getSession: builder.query<SessionDataFromGet['Session'], void>({
      query: () => ({
        url: '/bff/session',
        params: {data: 'Session'},
      }),
      keepUnusedDataFor: 60 * 60,
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data: sessionData} = await queryFulfilled;
          const CSRFToken = sessionData?.CSRFToken;

          if (CSRFToken) {
            await dispatch(api.endpoints.getBlendTalkFeature.initiate({CSRFToken})).unwrap();
          }

          if (sessionData?.loggedInUserId) {
            await dispatch(api.endpoints.getBlendTalkNotifications.initiate()).unwrap();
            await dispatch(api.endpoints.getMyTeamMenuItemAvailability.initiate()).unwrap();
          }
        } catch (err) {
          console.error('Error loading session or BlendTalkFeature:', err);
        }
      },
    }),
    getProfile: builder.query<{results: ProfileData}, {flow: string | null}>({
      query: ({flow}) => ({
        url: '/bff/profilewizard',
        params: flow ? {flow} : undefined,
      }),
      keepUnusedDataFor: 60 * 60,
      async onQueryStarted({}, {dispatch, getState, queryFulfilled}) {
        const state = getState() as RootState;
        const existingProfile = state.profile?.profileData;

        if (existingProfile && Object.keys(existingProfile).length > 0) {
          console.log('Profile already exists.');
          return;
        }

        try {
          const {
            data: {results: response},
          } = await queryFulfilled;

          dispatch(setProfileData(response || []));
        } catch (err) {
          console.error('Error loading profile:', err);
        }
      },
    }),
    getCountryByIp: builder.query<{userCountryByIp: string; countries: CountryInfo[]}, void>({
      queryFn: async () => {
        try {
          const res = await getCountriesInfo();
          return {data: {userCountryByIp: res?.data?.countryByIp || '', countries: res?.data?.list || []}};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch country info'}};
        }
      },
      keepUnusedDataFor: Infinity,
    }),
    getUserData: builder.query<UserData, void>({
      queryFn: async () => {
        try {
          const res: AxiosResponse<UserData> = await getUserDataReq();
          const data = res.data;

          return {data};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch user data'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getTopics: builder.query<OptionsProps[], {projectType: WizardProjectType}>({
      queryFn: async ({projectType}) => {
        try {
          const res = await fetchTopics(projectType);
          return {data: res};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getLanguages: builder.query<ILanguagesResult, void>({
      queryFn: async () => {
        try {
          const res = await fetchLanguages();
          return {data: res.results.Language};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getPriceConfig: builder.query<IPriceConfig, void>({
      queryFn: async () => {
        try {
          const res = await fetchPriceConfig();
          return {data: res.results.Price};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getTargetLanguages: builder.query<TargetLanguage[], string>({
      queryFn: async (slug: string) => {
        try {
          const res = await fetchTargetLanguages(slug);
          return {data: res.results.data};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getBlendTalkNotifications: builder.query<UnreadNotification[], void>({
      query: () => ({
        url: '/bff/chat-notifications',
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getMyTeamMenuItemAvailability: builder.query<boolean, void>({
      query: () => ({
        url: '/bff/my-team-menu-item',
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getAuthToken: builder.query<string, void>({
      queryFn: async () => {
        try {
          const data = await fetchAuthToken();
          return {data};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch auth token'}};
        }
      },
      keepUnusedDataFor: 10 * 60 * 1000,
    }),
    createLanguageSet: builder.mutation<
      void,
      {
        userId: string;
        CSRFToken: string;
        sets: SetMutationObject[];
      }
    >({
      query: ({userId, CSRFToken, sets}) => {
        const fd = new FormData();
        fd.append('user_id', userId);
        fd.append('config', JSON.stringify(sets));

        return {
          url: `/api/2/account/${userId}/language-sets`,
          method: 'POST',
          body: fd,
          params: {CSRFToken},
        };
      },
    }),
    getLanguagesSet: builder.query<{results: LanguageSetResponse}, {userId: string; CSRFToken: string}>({
      query: ({userId, CSRFToken}) => ({
        url: `/api/2/account/${userId}/language-sets`,
        params: {CSRFToken},
      }),
      keepUnusedDataFor: 60 * 60,
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const {
            data: {results: response},
          } = await queryFulfilled;

          const sets = JSON.parse(response.Config) as ILanguageSetResponse[];
          const setList: ILanguageSet[] = sets.map((data) => ({
            ...data,
            id: uuidv4(),
          }));

          dispatch(setLanguagesSets(setList || []));
        } catch (err) {
          console.error('Error fetching language sets:', err);
        }
      },
    }),
    getPrePostProcessingConfig: builder.query<IPrePostProcessingSettings, void>({
      queryFn: async () => {
        try {
          const res = await getIsPrePostProcessingSettings();

          return {data: res.data.results.PrePostProcessingSettings};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
    getRegistryDynamicProjects: builder.query<IRegistryDynamicProject[], void>({
      queryFn: async () => {
        try {
          const res = await getRegistryDynamicProjects();

          return {data: res.data.results.DynamicProjects};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch price quote'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetConfigQuery,
  useGetSessionQuery,
  useGetProfileQuery,
  useGetCountryByIpQuery,
  useGetUserDataQuery,
  useGetBlendTalkFeatureQuery,
  useGetTopicsQuery,
  useGetLanguagesQuery,
  useGetPriceConfigQuery,
  useGetTargetLanguagesQuery,
  useGetBlendTalkNotificationsQuery,
  useGetMyTeamMenuItemAvailabilityQuery,
  useCreateLanguageSetMutation,
  useGetLanguagesSetQuery,
  useGetPrePostProcessingConfigQuery,
  useGetRegistryDynamicProjectsQuery,
  useGetAuthTokenQuery,
} = api;
