import {CartDataProps} from '@/src/interfaces/types/thankyou.types';

import core from './core';

export const getCartProjects = (tid: string, CSRFToken: string): Promise<CartDataProps> =>
  core.get(`/ajax/cart/projects?tid=${tid}&CSRFToken=${CSRFToken}`).then(({data}) => data);

export function getIndustry() {
  return core.get('/userService/getIndustry');
}
