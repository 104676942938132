'use client';

import {Suspense} from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {StyleSheetManager, ThemeProvider} from 'styled-components';

import PageLayout from '@/src/components/GeneralComponents/PageLayout';
import RouteChange from '@/src/components/GeneralComponents/RouteChange/RouteChange';
import Providers from '@/src/components/Providers';
import WebSocketHandler from '@/src/components/Websocket';
import {PropsWithPreloadedState} from '@/src/interfaces/types/layout';
import StyledComponentsRegistry from '@/src/lib/registry';
import '@/theme/base.css';
import {OverrideStyles} from '@/theme/overrideStyles';
import theme from '@/theme/theme';

const AppLayout = ({children, preloadedState}: PropsWithPreloadedState) => (
  <StyledComponentsRegistry>
    <Providers preloadedState={preloadedState}>
      <StyleSheetManager shouldForwardProp={() => true}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <OverrideStyles />
          <Suspense fallback={<></>}>
            <RouteChange>
              <PageLayout>{children}</PageLayout>
              <WebSocketHandler />
            </RouteChange>
          </Suspense>
        </ThemeProvider>
      </StyleSheetManager>
    </Providers>
  </StyledComponentsRegistry>
);

export default AppLayout;
