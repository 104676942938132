export default {
  HEADER_TITLE: 'The BLEND Wizard',
  HEADER_BTN_TEXT: 'Reset',
  READY_TEXT: "Our linguists are ready to go! Let's get started 👇",
  UPLOAD_BLOCK_TITLE: "Let's upload your content",
  UPLOAD_BLOCK_FILE_TYPES: 'Supported file types',
  UPLOAD_BLOCK_KNOW_WC: 'Get to know our word count system',
  UPLOAD_BLOCK_WC_TITLE:
    "Automated word count may not be 100% accurate for file types like PDFs and images. We'll contact you if your project's actual word count differs from the estimate, which may impact project price.",
  UPLOAD_BLOCK_FILE_WC: 'Word Count',
  UPLOAD_BLOCK_FILE_PC: 'Page Count',
  UPLOAD_BLOCK_TYPED_TEXT: 'Typed text',
  UPLOAD_BLOCK_CUSTOM_VARIANT:
    'Select this option if your files contain additional content that is not intended for translation (instructions, hidden text, filtered columns or rows, HTML/VBA code, dropdown buttons) or if you want to receive a bilingual file.',
  UPLOAD_BLOCK_AS_IS_VARIANT:
    'Select this option to translate the entire document. Make sure to exclude any non-translatable text or instructions to avoid an extra charge.',
  UPLOAD_BLOCK_SIDEBAR_WIDTH: '140px',
  FILE_TRANSLATION_FROM_SOURCES: ['local_file_system', 'googledrive', 'box'],
  FILE_TRANSLATION_AS_IS_TITLE: 'My entire document can be translated as-is',
  FILE_TRANSLATION_CUSTOM_TITLE: 'My document contains content that should not be translated ($0.00 extra)',
  TEXT_INPUT_PLACEHOLDER: 'Type or paste your text here',
  LEARN_MORE: 'Learn more',
  ADDITIONAL_TEXT: 'Additional Services',
  SERVICE_EDITING_TEXT: 'Professional editing',
  SERVICE_CERTIFICATE_EDITING: 'Certificate of Accuracy',
  EDITING_TOOLTIP: 'A professional editor will review and edit your translation for guaranteed perfection.',
  SUMMARY_TITLE: 'Order Summary',
  TOTAL_WC: 'Total word count',
  TOTAL_PC: 'Total page count',
  EST_DELIVERY: 'Estimated delivery',
  ORDER_TOTAL: 'Order Total',
  TOTAL_MEDIA: 'Total length of media',
  REVIEW_ORDER: 'Review your order',
  TRANSLATION_TYPE_TEXT:
    'Convert written text from one language to another while maintaining meaning, tone and context',
  PROOFREADING_TYPE_TEXT: 'Get written text reviewed and revised to correct errors and ensure clarity and accuracy',
  TRANSCRIPTION_TYPE_TEXT: 'Convert spoken language into written text from audio or video recordings',
  PROJECT_TYPE_TITLE: 'Select project type',
  SUPPORTED_LANGUAGES: 'Supported languages',
  WHAT_CONTENT_ABOUT: 'What is your content about?*',
  WHAT_CONTENT_ABOUT_TOOLTIP:
    'Selecting your content’s subject helps us match your project with a translator who specializes in that domain, ensuring accurate translations.',
  TOPIC_DESCRIPTION: 'This will help us match you with an expert translator for the best results.',
  TRANSLATE_FROM: 'Translate from:',
  SELECT_LANGUAGE: 'Select language:',
  EDITING_TOOLTIP_TEXT:
    'Add a professional editor to review and refine your translation for polished results you can trust',
  CERTIFICATE_TOOLTIP_TEXT:
    'Add a Certificate of Accuracy to verify the translation is accurate and complete. See an example ',
  TRANSLATE_TO: 'Translate to:',
  CHOOSE_LANGUAGE: 'Choose language',
  ALL_LANGUAGES: 'All languages',
  FILTER_LANGUAGES_PLACEHOLDER: 'Type the first language letter to quick find...',
  SAVE_LANGUAGES: 'Save languages set for future projects',
  CLEAR: 'Clear',
  APPLY: 'Apply',
  SAVE_SET: 'Save set',
  LOAD_SET: 'Load set',
  SAVE_SET_TITLE: 'Save your set',
  LOAD_SET_TITLE: 'Load your set',
  LOAD_SET_TOOLTIP:
    'Save groups of languages you frequently order as a set. Manage multiple sets and select one as your default for quicker project submissions.',
  SAVE_SET_PLACEHOLDER: 'Text hint',
  SAVE_SET_AS_DEFAULT: 'Save as Default',
  SET_NAME: 'Set name',
  SET_EXISTS: 'This set already exists',
  LOAD_SET_SUBTITLE: 'In order to choose, select a set and click on load set',
  CANCEL: 'Cancel',
  SORT_BY: 'Sort by',
  BRIEF_TITLE: 'Add your project brief',
  BRIEF_INSTRUCTIONS: [
    'Target audience',
    'Words not to translate',
    'Content goals',
    'Reference URLs',
    'Keywords and terminology',
  ],
  BRIEF_PLACEHOLDER:
    'For example: We are expanding to a new market in Spain and it is important for us that you will translate the file using Spanish day-to-day jargon while keeping the formatting the same as the original document',
  BRIEF_MAX_SYMBOLS_NUMBER: 2500,
  FROM: 'from',
  BRIEF_TONE_OF_VOICE: 'Tone of voice',
  BRIEF_TONE_DESCRIPTION: "Get the best translation by selecting your content's tone of voice",
  BRIEF_REFERENCE: 'Include reference materials',
  BRIEF_TONE_OF_VOICE_PLACEHOLDER: 'Select tone of voice',
  BRIEF_UPLOAD_FILES: 'Upload files',
  BRIEF_REFERENCE_TOOLTIP_TITLE:
    'Upload reference files or instructions to give your translator additional context for best results.',
  SELECT: 'Select',
  LOAD_SET_TOOLTIP_TEXT:
    'You can select multiple languages and save them as a set. You can also create multiple sets, choosing one as your default.',
  WC_MIGHT_CHANGE: 'Word count might change',
  WC_CHANGE_TEXT1:
    "Our automatic word count may not be 100% accurate for file types like PDFs and images... nobody's perfect, right?",
  WC_CHANGE_TEXT2:
    "We'll let you know if your project's actual word count differs from the estimate, which may impact project price.",
  WC_CHANGE_TEXT3: 'For precise word counts, feel free to send your files to support@team.getblend.com.',
  WC_CHANGE_TEXT4:
    'We offer direct translation of .indd/idmi files at the same price. If you have files in this format, kindly upload them instead of the PDF (please note that the translated file will not be print-ready).',
  SUBMIT_MODAL_BUTTON_TEXT: 'I understand',
  DONT_SHOW_AGAIN: 'Do not show again',
  WC_CALCULATE_TITLE: 'Unable to calculate word count',
  PC_CALCULATE_TITLE: 'Incorrect Page Count',
  WC_CALCULATE_TEXT1:
    "Looks like we can't calculate your word count. Don't worry, you can enter it manually or upload your content in text format.",
  WC_CALCULATE_TEXT2:
    "Alternatively, send your file to info@team.getblend.com and we'll manually calculate the word count for you.",
  PC_CALCULATE_TEXT1:
    'Please note: The automatic page counter could NOT count the number of pages in the file you uploaded. Some files, such as image files, non-editable PDFs, scans cannot be counted automatically and can only be counted using an OCR (Optical Character Recognition) software.',
  PC_CALCULATE_TEXT2: 'Please enter the number of pages manually.',
  OK: 'OK',
  WE_APOLOGIZE: 'We apologize for the inconvenience.',
  PDF_FILETYPE: 'application/pdf',
  WC_INACCURATE_MODAL: 'prevent_inaccurate_wc_modal',
  FILES_ERROR_TEXT: 'Please attach a file, type a text or add word count',
  PAGES_ERROR_TEXT: 'Please attach a file, type a text or add page count',
  SOURCE_LANG_ERROR: 'Please select the language you are translating your text from',
  TARGET_LANG_ERROR: 'Please select the language(s) you need your content translated to',
  TOPIC_ERROR: 'Please choose an expertise',
  CERTIFICATE_NOTE_1:
    'Please check in advance that our Certificate of Accuracy is accepted by the institution requesting the translation. You may download an example of our certificate ',
  CERTIFICATE_NOTE_2:
    'We will not be able to refund certificate translation projects in cases where the certificate is not accepted.',
  CERTIFICATE_NOTE_TITLE: 'IMPORTANT!',
  CERTIFICATE_NOTE_SUBTITLE: 'Insert the full names of all relevant persons mentioned in the document in ',
  CERTIFICATE_EXAMPLE_LINK: 'https://app.getblend.com/public/resources/CertificateSample.pdf',
  HERE: 'here',
  CERTIFICATE_INPUT_PLACEHOLDER: 'Text hint',
  EXCEEDED_CHARACTER_LIMIT: 'Exceeded character limit',
  PREPROCESSING_FEATURE_ID: 'ce0031f8-b206-4e8c-97b4-2dc73f1eab84',
  LENGTH: 'Length',
};
