import {FC, useCallback, useEffect, useMemo, useState} from 'react';

import {usePathname} from 'next/navigation';
import styled from 'styled-components';

import UserBar from '@/src/components/GeneralComponents/MainHeader/OHTUserBar';
import {sanitizePath} from '@/src/components/GeneralComponents/PageLayoutUtils';
import SidebarItem from '@/src/components/GeneralComponents/Sidebar/components/SidebarItem/SidebarItem';
import SmallSidebarItem from '@/src/components/GeneralComponents/Sidebar/components/SidebarItemSmall/SidebarItemSmall';
import {getNavItems, MainMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import useMobile from '@/src/hooks/useMobile';
import {useSession} from '@/src/hooks/useSession';
import {useAppDispatch, useAppSelector} from '@/src/lib/store/hooks';
import {
  useGetBlendTalkFeatureQuery,
  useGetMyTeamMenuItemAvailabilityQuery,
  useGetUserDataQuery,
} from '@/src/lib/store/stores/api';
import {setToggleSidebar} from '@/src/lib/store/stores/sidebar';
import {USER_ROLES} from '@/src/types/Wizard/types';

const {customer} = USER_ROLES;

interface SidebarProps {
  onOpenSideBar: (key?: string) => void;
  initiallySelectedNav: string;
  setInitiallySelectedNav: (key: string) => void;
  isHidden: boolean;
}

const SidebarView: FC<SidebarProps> = ({onOpenSideBar, initiallySelectedNav, setInitiallySelectedNav, isHidden}) => {
  const [locationHash, setLocationHash] = useState<string>('');

  const pathname = usePathname();

  const dispatch = useAppDispatch();
  const sidebarToggle = useAppSelector((state) => state.sidebar.sidebarToggle);
  const {profileData} = useAppSelector((state) => state.profile);
  const handleSidebarToggle = useCallback((value: boolean) => dispatch(setToggleSidebar(value)), [dispatch]);
  const session = useSession();
  const {loggedInUserId = 0} = useSession() || {};

  const {data} = useGetBlendTalkFeatureQuery({CSRFToken: session?.CSRFToken || ''}, {skip: !session?.CSRFToken});

  const isShowBlendTalkFeature = !!data;

  const {data: isShowMyTeamMenuItem} = useGetMyTeamMenuItemAvailabilityQuery(undefined, {skip: !loggedInUserId});
  const {data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);
  const {isLaptop} = useMobile();

  const {myTeamStatus, type: userType = customer} = userData ?? {};

  const navMenu = useMemo<MainMenuItem[]>(
    () =>
      getNavItems(!!myTeamStatus, isShowBlendTalkFeature, !!isShowMyTeamMenuItem, userType)?.[userType.toLowerCase()] ||
      getNavItems(!!myTeamStatus, isShowBlendTalkFeature, !!isShowMyTeamMenuItem, userType)?.translator,
    [userType, myTeamStatus, isShowBlendTalkFeature, isShowMyTeamMenuItem]
  );

  useEffect(() => {
    const currentMenu = navMenu.find(({submenu}) =>
      submenu?.find(({to}) => sanitizePath(to) === `${sanitizePath(pathname || '')}${locationHash}`)
    );

    (currentMenu || pathname === '/profile/') && setInitiallySelectedNav(currentMenu ? currentMenu.key : 'profile');
  }, [pathname, locationHash, navMenu]);

  const getAvailableSubMenuItems = useCallback(
    ({submenu, type: mainType}: MainMenuItem) =>
      submenu?.filter(
        ({userTypes, type: tabType, key}) =>
          (!userTypes || userTypes.includes(userType)) &&
          (!tabType || (tabType === 'loggedIn' && isLoggedIn)) &&
          (mainType === 'all' ||
            (mainType === 'loggedIn' && isLoggedIn) ||
            profileData?.sections?.find((section) => {
              return [section, 'myTeam', 'ourPartners', 'apiDocumentation', 'accountInformation', 'messages'].includes(
                key
              );
            }))
      ) || [],
    [isLoggedIn, profileData, userType]
  );

  const getMenuItemClasses = useCallback(
    (key: string, to?: string) => `${initiallySelectedNav === key || (to && pathname?.includes(to)) ? 'active' : ''} `,
    [initiallySelectedNav, pathname]
  );

  const handleOpenClosedSidebar = useCallback((key = '') => onOpenSideBar(key), [onOpenSideBar]);
  const sidebarSize: number = useMemo(() => (sidebarToggle ? 280 : 88), [sidebarToggle]);

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }
    setLocationHash(window.location.hash);

    const handleHashChange = () => {
      setLocationHash(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [pathname]);

  const filteredNavMenu = useMemo(
    () => navMenu.filter(({invisible, userTypes}) => invisible || !userTypes || userTypes.includes(userType)),
    [navMenu, userType]
  );

  return (
    <Wrapper $islaptop={+isLaptop}>
      <UserBar isOpenSidebar={sidebarToggle} setSidebarToggle={handleSidebarToggle} isHidden={isHidden} />
      {sidebarToggle ? (
        <SidebarOpen $sidebarSize={sidebarSize}>
          {filteredNavMenu.map((item) => {
            const availableSubMenus = getAvailableSubMenuItems(item);

            return (
              <SidebarItem
                isStartOpen={initiallySelectedNav === item.key}
                item={item}
                key={item.key}
                availableSubMenus={availableSubMenus}
                onOpenSideBar={onOpenSideBar}
              />
            );
          })}
        </SidebarOpen>
      ) : (
        <SidebarBlock $sidebarSize={sidebarSize}>
          <SidebarClose>
            {filteredNavMenu.map((item) => (
              <SmallSidebarItem
                key={item.key}
                item={item}
                getMenuItemClasses={getMenuItemClasses}
                onOpenClosedSidebar={handleOpenClosedSidebar}
                userType={userType}
              />
            ))}
          </SidebarClose>
        </SidebarBlock>
      )}
    </Wrapper>
  );
};

const SidebarOpen = styled.div<{$sidebarSize: number}>`
  overflow-y: auto;
  margin-top: 2px;
  z-index: 3;
  transition: all 1s ease-out;
  position: fixed;
  top: 159px;
  height: calc(100% - 192px);
  width: ${({$sidebarSize}) => $sidebarSize}px;
  background: ${({theme}) => theme.colors.grey000};

  &::-webkit-scrollbar {
    width: 0.4vw;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193, 0.5);
    border-radius: 50px;
  }
`;

const SidebarBlock = styled.div<{$sidebarSize: number}>`
  transition: all 0.15s ease-out;
  position: fixed;
  top: 159px;
  z-index: 1;
  height: calc(100% - 90px);
  width: ${({$sidebarSize}) => $sidebarSize}px;
  background-color: ${({theme}) => theme.colors.grey000};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const SidebarClose = styled.div`
  display: grid;
  padding: 3px 10px 20px 10px;
  justify-items: center;
  box-sizing: border-box;
`;

const Wrapper = styled.div<{$islaptop: number}>`
  box-shadow: ${({$islaptop}) => `${$islaptop ? 'none' : '1px -1px 6px 0 rgb(200, 200, 200)'}`};
`;

export default SidebarView;
