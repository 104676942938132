import {FC} from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import styled from 'styled-components';

import Arrow from '@/assets/icons_refactor/Common/long-arrow.svg';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import useConfig from '@/src/hooks/useConfig';
import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';

import Consts from '../../../AuthModalConsts';
import ErrorMessageText from '../../../Form/ErrorMessageText';

const {TERMS, CREATE_ACCOUNT_TITLE, PRIVACY, LOGIN, HAVE_ACC} = Consts;

const {login} = ModalMode;

interface Props {
  handleOpenModal: (type: ModalMode) => void;
  agreeTermsValue: boolean;
  setAgreeTermsValue: (value: boolean) => void;
}

const Footer: FC<Props> = ({handleOpenModal, agreeTermsValue, setAgreeTermsValue}) => {
  const {blendDomainURL = '', companyName = ''} = useConfig() || {};

  const termsOfUseUrl = `https://${blendDomainURL}/terms-and-conditions`;
  const privacyPolicyUrl = `https://${blendDomainURL}/privacy-policy`;

  const {errorMessages, isFetchingAuth} = useUserData();
  const {agree_terms} = errorMessages;

  return (
    <Wrapper>
      <ErrorMessage>
        <ErrorMessageText data-qa-auto="erragree" message={agree_terms && agree_terms[0]} />
      </ErrorMessage>
      <AgreeTerms>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTermsValue}
                name="agreeTerms"
                color="primary"
                onChange={({target: {checked}}) => {
                  setAgreeTermsValue(checked);
                }}
                inputProps={{'aria-label': 'primary checkbox'}}
                disabled={isFetchingAuth}
              />
            }
            label=""
          />
        </FormControl>
        {CREATE_ACCOUNT_TITLE} {companyName}&#39;s
      </AgreeTerms>
      <div>
        <Link href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">
          {TERMS}
        </Link>
        &nbsp;&&nbsp;
        <Link href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
          {PRIVACY}
        </Link>
      </div>

      <SwitchBox>
        {HAVE_ACC}
        <Button data-qa-auto="switch-to-login-mode" onClick={() => handleOpenModal(login)} disableRipple>
          {LOGIN}
          <Icon icon={Arrow} boxW={20} boxH={20} />
        </Button>
      </SwitchBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 0.9rem;
  color: ${({theme}) => theme.colors.green084};
  text-align: center;
  font-weight: 300;

  & a,
  & button {
    padding: 0;
    color: ${({theme}) => theme.colors.blue093};
    vertical-align: baseline;
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.colors.blue093};
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

const Button = styled(BaseButton)`
  margin-left: 5px;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
`;

const AgreeTerms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & label {
    margin: 0;

    & > span {
      padding: 5px;
    }
  }
`;

const SwitchBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.green084};
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue093};
  margin-left: 2px;
`;

export default Footer;
