import {useEffect, useRef} from 'react';

import {Centrifuge} from 'centrifuge';
import {useSearchParams} from 'next/navigation';

import {getCartProjects} from '@/src/api/thankyou';
import useConfig from '@/src/hooks/useConfig';
import {useSession} from '@/src/hooks/useSession';
import {useAppDispatch} from '@/src/lib/store/hooks';
import {useGetAuthTokenQuery} from '@/src/lib/store/stores/api';
import {setCartData, setErrorMessage} from '@/src/lib/store/stores/thankYouCart';
import {WSEvent} from '@/src/types/Websocket/types';

const {CART_PRODUCT_SUBMITTED} = WSEvent;

const WebSocketHandler = () => {
  const {data: token, refetch: refetchToken} = useGetAuthTokenQuery(); // Получаем токен и refetch
  const centrifugeRef = useRef<Centrifuge | null>(null);
  const searchParams = useSearchParams();
  const tid: string | null = searchParams.get('tid');
  const {CSRFToken = ''} = useSession() || {};
  const dispatch = useAppDispatch();
  const config = useConfig();

  useEffect(() => {
    if (!token || !config?.wizard?.wsUrl) {
      return;
    }

    const initializeWebSocket = (authToken: string) => {
      if (centrifugeRef.current) {
        centrifugeRef.current.disconnect();
      }

      const centrifugeInstance = new Centrifuge(config.wizard.wsUrl as string, {
        getToken: async () => {
          return authToken;
        },
        timeout: 8000,
      });

      centrifugeInstance.on('error', async (error) => {
        console.error('WebSocket error occurred:', error);

        try {
          const newToken = await refetchToken().unwrap();

          initializeWebSocket(newToken);

          getCartProjects(tid!, CSRFToken).then(({results}) => {
            if (results) {
              dispatch(setCartData(results));
            }
          });
        } catch (err) {}
      });

      centrifugeInstance.on('publication', ({data}) => {
        if (data.event === CART_PRODUCT_SUBMITTED) {
          data.status === false && dispatch(setErrorMessage(data?.error_message || ''));

          getCartProjects(tid!, CSRFToken).then(({results}) => {
            if (results) {
              dispatch(setCartData(results));
            }
          });
        }
      });

      centrifugeInstance.connect();
      centrifugeRef.current = centrifugeInstance;
    };

    initializeWebSocket(token);

    return () => {
      if (centrifugeRef.current) {
        centrifugeRef.current.disconnect();
        centrifugeRef.current = null;
      }
    };
  }, [token, config, tid, CSRFToken, dispatch, refetchToken]);

  return null;
};

export default WebSocketHandler;
