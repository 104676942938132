import {CartProjectsProps, PurchaseInfoProps} from '@/src/interfaces/types/thankyou.types';

export default {
  PAGE_TITLE: 'Payment confirmation',
  START_PROJECT: '+ Start new project',
  PROJECTS_SUBMITTED: 'Congratulations, your project has been submitted!',
  PROJECTS_SUBMITTED_NOTE: ' We’ll take it from here.',
  PURCHASE_SUBMITTED: 'Thank you. Your order was successful!',
  PROJECTS_TITLE: 'Project summary',
  CREDITS_TITLE: 'Purchase summary',
  INFO_MESSAGE: "Note: We've created a sub-project for each target language so it's easier for you to track",
  PAYMENT_CONFIRMATION: 'Payment confirmation',
  ACCOUNT_BALANCE: 'Account balance',
  NO_PERMISSIONS:
    'Oops, it seems like you do not have enough privileges to access this page’s content. We apologize for the inconvenience. Please contact our support team. Thank you!',
  THANK_YOU_NOTE: "Want to monitor your project's progress or communicate with your translator? Go to the ",
  THANK_YOU_PURCHASE: 'Thank you for your purchase of ',
  OUR_LINK_SUBTEXT: 'To begin your translation project please go to our ',
  OUR_LINK_TEXT: 'self service wizard',
  CREDIT_PARAGRAPH_1:
    'If you are a business or enterprise client, we recommend using the Translate & Editing option. This way, upon completion of your translation by your original translator, your text will be sent to a second senior editor for further review and revision if needed.',
  CREDIT_PARAGRAPH_2:
    'Our system will automatically find the most suitable translator to begin working on your project ASAP. A countdown timer will appear showing you when the translation will be ready.',
  CREDIT_PARAGRAPH_3:
    'You can communicate directly with your translator for further changes or explanations on the project page. Translation volume: 200 words per hour/2000 words a day per translator, once the translation has begun.',
  FURTHER_ASSISTANCE: 'If you need further assistance please contact us directly here ',
  HERE: 'here',
  ONCE_AGAIN_THANK_YOU: 'Once again, we thank you for your business,',
  THANK_YOU_SUPPORT: 'BLEND client support team',
  DETAILS_ABOUT_YOU: 'A few details about you before we continue',
  PROJECTS_PAGE: ' Projects page',
  SUBMITTING_PROJECTS_IN_PROGRESS: 'Submitting projects for you. This can take a minute or two.',
};

export const paymentData: PurchaseInfoProps[] = [
  {
    title: 'Payment confirmation',
    subTitle: 'Receipt number',
    value: '',
  },
  {
    title: 'Payment confirmation',
    subTitle: 'Amount',
    value: '',
  },
  {
    title: 'Payment confirmation',
    subTitle: 'Date',
    value: '',
  },
  {
    title: 'Account balance',
    subTitle: 'Spent:',
    value: '',
  },
  {
    title: 'Account balance',
    subTitle: 'Remaining balance:',
    value: '',
  },
];

export const initialCartData: CartProjectsProps = {
  cartAmount: 0,
  cartCredits: 0,
  cartHasErrors: false,
  currency: '',
  date: '',
  date_unix_timestamp: 0,
  firstTimeCustomer: true,
  invoice: 0,
  isFirstPurchase: true,
  method: '',
  payment: 0,
  paymentCredits: 0,
  paymentNoVat: 0,
  poid: '',
  projects: [],
  type: '',
  userBalance: 0,
  userBalanceAmount: 0,
};
