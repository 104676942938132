import {MouseEvent, useCallback, useMemo} from 'react';

import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

import BaseLink from '@/src/components/BaseComponents/BaseLink';
import useConfig from '@/src/hooks/useConfig';
import useMobile from '@/src/hooks/useMobile';
import useUserData from '@/src/hooks/useUserData';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {USER_ROLES} from '@/src/types/Wizard/types';
import {attenFont, font_large} from '@/theme/fonts';

import {getNavItems} from './NavConsts';
import {CustomNavDropdown} from './NavDropdown';
import CONSTS from '../../HeaderConsts';

const {
  NAV_MENU_TYPES: {LINK, DROPDOWN},
} = CONSTS;

const Navs = () => {
  const {isMobile} = useMobile();
  const {blendDomainURL = ''} = useConfig() || {};
  const {data: userData} = useGetUserDataQuery();

  const {
    adminSubmarine: {openAdminActions, setSubLocation},
  } = useUserData();
  const {isAdmin, type, myTeamStatus} = userData || {};

  const navItems = useMemo(
    () => getNavItems(type || USER_ROLES.unlogged, {blendDomainURL, myTeamStatus}),
    [type, blendDomainURL, myTeamStatus]
  );

  const openAdminClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setSubLocation(event.clientX, event.clientY);
      openAdminActions();
    },
    [openAdminActions, setSubLocation]
  );

  return (
    <Wrapper className={`${isMobile ? 'mobile' : 'desktop'}-nav nav`}>
      {!isMobile &&
        navItems.map(
          ({type, data: {title, href, links}, action}) =>
            (type === LINK && (
              <StyledLink key={title} onClick={action}>
                {['Support'].includes(title) ? (
                  <div>{title}</div>
                ) : (
                  <MenuLinkItem href={href} target={['Support'].includes(title) ? '_blank' : '_self'}>
                    {title}
                  </MenuLinkItem>
                )}
              </StyledLink>
            )) ||
            (type === DROPDOWN && (
              <CustomNavDropdown title={title} key={title}>
                {links &&
                  links.map(({title: linkTitle, href: linkHref}: any) => (
                    <MenuItemStyled key={linkTitle} onTouchStart={(e: any) => e.stopPropagation()}>
                      <MenuLinkItem href={linkHref}>{linkTitle}</MenuLinkItem>
                    </MenuItemStyled>
                  ))}
              </CustomNavDropdown>
            ))
        )}

      {isMobile && isAdmin && (
        <StyledLink>
          <div onClick={openAdminClick}>Open admin actions</div>
        </StyledLink>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const MenuItemStyled = styled(MenuItem)`
  &.MuiButtonBase-root.MuiMenuItem-root:hover {
    background-color: #ff7800;
  }

  &.MuiButtonBase-root.MuiMenuItem-root {
    background-color: transparent;
  }
`;

const MenuLinkItem = styled(BaseLink)`
  &.MuiTypography-root {
    color: ${({theme}) => theme.colors.grey000};
    ${attenFont};
    ${font_large};
    text-decoration: none;
    background-color: transparent;
  }
`;

const StyledLink = styled(MenuItem)`
  color: ${({theme}) => theme.colors.grey000};
  ${attenFont};
  ${font_large};
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  box-sizing: border-box;

  :hover {
    color: rgb(138, 136, 255);
  }
`;

export default Navs;
