import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {fetchPriceQuote} from '@/src/api/wizard';
import CONSTS from '@/src/components/Wizard/WizardViewConsts';
import {
  ContentTypeEnum,
  ErrorFields,
  ExperiseIdEnum,
  FilesTranslationVariantEnum,
  LanguageProps,
  TargetLanguage,
} from '@/src/components/Wizard/WizardViewUtils';
import {StoreDefaultTypes} from '@/src/interfaces/types/store.types';
import {WizardProjectType} from '@/src/types/Wizard/types';
import {removeItem} from '@/src/utils/localStorage';

const {SOURCE_LANG_ERROR, TARGET_LANG_ERROR} = CONSTS;
const {PROOFREADING, TRANSCRIPTION, TRANSLATION} = WizardProjectType;

export interface WizardData {
  projectType: WizardProjectType;
  contentType: ContentTypeEnum;
  textResource?: ITextResource | null;
  files?: FileData[] | null;
  professionalEditing: boolean;
  sendCertificate: boolean;
  cartificateNote: string;
  topic: string;
  sourceLanguage?: LanguageProps;
  targetLanguages?: TargetLanguage[];
  brief: string;
  toneOfVoice?: string;
  briefFiles: FileData[];
  errors: ErrorFields<string[] | null>;
  isPageCount: boolean;
  wizardVersion: number;
}

export interface IPriceQuotePriceAdj {
  certificateCost?: number;
  editingCost?: number;
  translationCost?: number;
}

export interface IPriceQuote {
  currency: string;
  price: number;
  po: {
    poid: string;
    status: string;
    totalWordCount: number;
    type: string;
    deliveryData: {
      origin: {
        cost: {
          localized: number;
        };
        timeEstimation: number;
      };
    };
    resources: ITextResource[];
    _id: {
      $oid: string;
    };
  };
  priceAdjustment: IPriceQuotePriceAdj;
  price_breakdown?: Record<string, number>;
}

export interface FileData {
  uuid: string;
  filename: string;
  wordcount: number;
  type: string;
  is_scanned: boolean;
  size: number;
  mimeType: string;
  isExcelFile: boolean;
  fileTranslationVariant?: FilesTranslationVariantEnum;
  error?: string;
  wasProcessed?: boolean;
  pageCount?: number;
}

export interface ITextResource {
  uuid: string;
  word_count: number;
  word_count_auto: number;
  word_count_manual: number;
  filetype: '';
  type: string;
  context: 'source';
  filename: string;
  text: string;
  isExcelFile: boolean;
}

export type StorageFileData = ITextResource & FileData;

export interface IFileWordCountChange {
  uuid: string;
  wordCount: number;
}

export interface ILanguageSetResponse {
  setName: string;
  sourceLanguage: string;
  targetLanguages: string[];
  default: number;
}

export interface ILanguageSet extends ILanguageSetResponse {
  id: string;
}

export interface IWizardState extends StoreDefaultTypes {
  data: WizardData;
  priceQuote?: IPriceQuote;
  languagesSets?: ILanguageSet[];
}

export const wizardDefaultErrors: ErrorFields<string[] | null> = {
  files: null,
  languages: null,
  topic: null,
  brief: null,
};

const initialState: IWizardState = {
  data: {
    projectType: TRANSLATION,
    contentType: ContentTypeEnum.FILES,
    professionalEditing: false,
    sendCertificate: false,
    topic: '',
    cartificateNote: '',
    brief: '',
    briefFiles: [],
    errors: wizardDefaultErrors,
    isPageCount: false,
    wizardVersion: 3,
  },
  loading: false,
};

let currentAbortController: AbortController | null = null;

export const getPriceQuote = createAsyncThunk<IPriceQuote, FormData>(
  'wizard/getPriceQuote',
  async (data: FormData, {rejectWithValue}) => {
    try {
      if (currentAbortController) {
        currentAbortController?.abort();
      }

      currentAbortController = new AbortController();
      const signal = currentAbortController.signal;

      const result: IPriceQuote = await fetchPriceQuote(data, signal);
      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Unknown error in getPriceQuote occured');
    }
  }
);

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setWizardProjectType(state, {payload}: PayloadAction<WizardProjectType>) {
      state.data.projectType = payload;

      if ([PROOFREADING, TRANSCRIPTION].includes(payload)) {
        state.data.contentType = ContentTypeEnum.FILES;
        if (state.data.topic === String(ExperiseIdEnum.CERTIFICATE)) {
          state.data.topic = initialState.data.topic;
          state.data.isPageCount = false;
        }
      }
    },
    setContentType(state, {payload}: PayloadAction<ContentTypeEnum>) {
      state.data.contentType = payload;
    },
    setFileTranslationVariant(state, {payload}: PayloadAction<{uuid: string; variant: FilesTranslationVariantEnum}>) {
      const file = state.data.files?.find(({uuid}) => uuid === payload.uuid);
      if (file) {
        delete file.error;
        file.fileTranslationVariant = payload.variant;
      }
    },
    updateFileData(state, {payload}: PayloadAction<{uuid: string | string[]; newContent: Partial<FileData>}>) {
      if (!state.data.files) {
        return;
      }
      const uuidList: string[] = Array.isArray(payload.uuid) ? payload.uuid : [payload.uuid];
      const filesCopy = [...state.data.files];
      uuidList.forEach((uuid: string) => {
        const file = filesCopy.find(({uuid: fileUuid}) => fileUuid === uuid);
        if (file) {
          Object.assign(file, payload.newContent);
        }
      });
      state.data.files = [...filesCopy];
    },
    setWizardText(state, {payload}: PayloadAction<ITextResource>) {
      state.data.textResource = payload;
    },
    addFile(state, {payload}: PayloadAction<FileData | FileData[]>) {
      const files = state.data.files || [];
      state.data.files = Array.isArray(payload) ? [...files, ...payload] : [...files, payload];
    },
    removeFile(state, {payload}: PayloadAction<string>) {
      const oldFiles: FileData[] = state.data.files || [];
      const newFiles: FileData[] = oldFiles.filter(({uuid}) => uuid !== payload);
      state.data.files = newFiles.length ? newFiles : null;
    },
    changeTextResourceWordCount(state, {payload}: PayloadAction<number>) {
      if (state.data.textResource) {
        state.data.textResource.word_count = payload;
        state.data.textResource.word_count_manual = payload;
      }
    },
    removeTextResource(state) {
      state.data.textResource = null;
    },
    changeFileWordCount(state, {payload}: PayloadAction<IFileWordCountChange | IFileWordCountChange[]>) {
      if (!state.data.files) {
        return;
      }
      const payloadToArray: IFileWordCountChange[] = Array.isArray(payload) ? payload : [payload];
      const oldFiles: FileData[] = state.data.files;
      payloadToArray.forEach(({uuid, wordCount}) => {
        const file = oldFiles.find(({uuid: fileUuid}) => fileUuid === uuid);

        if (!file || !state.data.files?.length) {
          return;
        }
        if (state.data.isPageCount) {
          file.pageCount = wordCount;
        } else {
          file.wordcount = wordCount;
        }
      });
      state.data.files = [...oldFiles];
    },
    setProfessionalEditing(state, {payload}: PayloadAction<boolean>) {
      state.data.professionalEditing = payload;
    },
    setSendCertificate(state, {payload}: PayloadAction<boolean>) {
      state.data.sendCertificate = payload;
    },
    changeTopic(state, {payload}: PayloadAction<string>) {
      state.data.topic = payload;
      state.data.isPageCount = payload === String(ExperiseIdEnum.CERTIFICATE);
      if (state.data.errors.topic) {
        state.data.errors.topic = null;
      }
    },
    changeSourceLanguage(state, {payload}: PayloadAction<LanguageProps | undefined>) {
      state.data.sourceLanguage = payload;
      state.data.targetLanguages = undefined;
      if (state.data.errors.languages) {
        state.data.errors.languages = state.data.errors.languages.filter((item) => item !== SOURCE_LANG_ERROR);
      }
    },
    setTargetLanguages(state, {payload}: PayloadAction<TargetLanguage[] | undefined>) {
      state.data.targetLanguages = payload;
      if (state.data.errors.languages) {
        state.data.errors.languages = state.data.errors.languages.filter((item) => item !== TARGET_LANG_ERROR);
      }
    },
    setBriefText(state, {payload}: PayloadAction<string>) {
      state.data.brief = payload;
    },
    setToneOfVoice(state, {payload}: PayloadAction<string>) {
      state.data.toneOfVoice = payload;
    },
    addBriefFile(state, {payload}: PayloadAction<FileData[]>) {
      state.data.briefFiles = [...state.data.briefFiles, ...payload];
    },
    removeBriefFile(state, {payload}: PayloadAction<string>) {
      state.data.briefFiles = state.data.briefFiles.filter(({uuid}) => uuid !== payload);
    },
    setLanguagesSets(state, {payload}: PayloadAction<ILanguageSet[]>) {
      state.languagesSets = payload;
    },
    setWizardErrors(state, {payload}: PayloadAction<WizardData['errors']>) {
      state.data.errors = payload;
      state.data.files?.forEach((file) => {
        if (file.isExcelFile && !file.fileTranslationVariant) {
          file.error = 'Please select one of the above options to continue';
        }
      });
    },
    setCertificateNote(state, {payload}: PayloadAction<string>) {
      state.data.cartificateNote = payload;
    },
    resetWizardState(state) {
      state.data = {...initialState.data};
      removeItem('poData');
    },
    setWizardInitData(state, {payload}: PayloadAction<WizardData>) {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceQuote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPriceQuote.fulfilled, (state, {payload}: PayloadAction<IPriceQuote>) => {
        state.priceQuote = payload;
        state.loading = false;
      })
      .addCase(getPriceQuote.rejected, (state, {payload}: PayloadAction<any>) => {
        state.loading = false;
        state.error = payload || 'Error loading price quote';
      });
  },
});

export const {
  setContentType,
  setFileTranslationVariant,
  setWizardText,
  addFile,
  removeFile,
  changeFileWordCount,
  setWizardProjectType,
  setProfessionalEditing,
  setSendCertificate,
  changeTopic,
  changeSourceLanguage,
  setTargetLanguages,
  setBriefText,
  setToneOfVoice,
  addBriefFile,
  removeBriefFile,
  setLanguagesSets,
  removeTextResource,
  changeTextResourceWordCount,
  setWizardErrors,
  updateFileData,
  resetWizardState,
  setCertificateNote,
  setWizardInitData,
} = wizardSlice.actions;
export default wizardSlice.reducer;
