import axios, {AxiosError} from 'axios';

const baseURL = process.env.FRONTEND_API_BASE_URL;

export const instance = axios.create({baseURL});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.code && !['ECONNABORTED', 'ERR_CANCELED'].includes(error.code)) {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
