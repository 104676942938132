'use client';

import {useEffect} from 'react';

import {usePathname} from 'next/navigation';

import useTracking from '@/src/hooks/useTracking';
import {GTMEvents} from '@/src/utils/globalEvents';

const RouteChange = ({children}: {children: React.ReactNode}) => {
  const pathname = usePathname(); // Получаем текущий путь
  const {gtmTrack} = useTracking();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    try {
      gtmTrack({
        event: GTMEvents.page_view,
        page_location: window.location.href,
        page_title: document.title,
      });
    } catch (error) {
      console.error('GTM track error: ', error);
    }
  }, [pathname, gtmTrack]);

  return <>{children}</>;
};

export default RouteChange;
