import {SocialNetworkData} from '@/src/interfaces/types/config.types';

export interface ApiResponse<ResultType> {
  status: object;
  results: ResultType;
  errors: any[];
}

export interface po {
  _id?: {
    $oid: string;
  };
  type: string;
  status: string;
  source_language?: string;
  target_languages?: string[];
  expertise?: string;
  material_expertise?: string;
  note?: string;
  saveNote: string;
  service: string;
  resources?: (Resource | TypedTextResource)[];
  totalWordCount?: string;
  useTM?: boolean;
  send_certificate: string;
  allowToUseTm?: boolean;
  errorTypes?: any[];
  errorMessages?: any[];
  updated_at?: number;
  created_at?: number;
  projectType: string;
  certificate_note: string;
  entQ: string;
  poid?: string;
  tag?: any[];
  noteResource?: object;
}

export type changedPOPart = {
  [key in keyof po]?: any;
};

export type handleChangePO = (changedPOPart: changedPOPart) => void;

export interface PurchaseOrderFromGet {
  PurchaseOrder: {
    po: po;
    price: number;
    currency: string;
    time: number;
    minPurchase: number;
    skipPlansPage: boolean;
  };
}

export interface AffiliateData {
  affiliate_account: {
    uuid: string;
    'short-name': string;
  };
  affiliate_name: string;
  affiliate_image: string;
}

export interface SessionDataFromGet {
  Session: {
    CSRFToken: string;
    affiliate?: AffiliateData | null;
    notice: string;
    voucher: string;
    sid: string;
    loggedInUserId: number | null;
    snData?: SocialNetworkData;
  };
}

export interface ConfigDataFromGet {
  Config: {
    captchaApiKey?: string;
    socialNetwork?: string;
    companyName?: string;
    blendDomainURL: string;
    appDomainURL?: string;
    infoEmailAddress?: string;
    upload: UploadConfig;
    wizard: {
      supportedFileTypesUrl: string;
      additionalContentUrl: string;
      supportedLanguagesUrl: string;
      wsUrl: string;
    };
  };
}

export interface UploadConfig {
  filepickerAPIkey: string;
}

export type ApiConfigResponse = ApiResponse<ConfigDataFromGet>;

export interface AddBudgetFromPost {
  cartKey: string;
}

export type ApiSessionResponse = ApiResponse<SessionDataFromGet>;
export interface LoginRequestParams {
  username: string;
  password: string;
  '2fa_code'?: string;
  captcha?: string | null;
  'captcha-service'?: any;
}

export interface PostSignupParams {
  full_name: string;
  user_email: string;
  agree_terms: string;
  user_type: string;
  user_country: string;
  company_name: string;
  'g-recaptcha-response'?: string | null;
  action: 'reg';
  pwd: string;
  registrationPage: string;
  captcha_service: any;
}

export interface PostCreatePasswordParams {
  password: string;
  password_verify: string;
  token: string;
  'g-recaptcha-response': string;
  CSRFToken: string;
}

export interface AdminSubmarineData {
  isAdminActionsOpen: boolean;
  subX: number;
  subY: number;
  setSubLocation: (x: number, y: number) => void;
  openAdminActions: () => void;
  closeAdminActions: () => void;
  setDimensions: any;
  dimensions: {minWidth: string; minHeight: string};
}

export enum USER_ROLES {
  admin = 'Admin',
  unlogged = 'unlogged',
  customer = 'Customer',
  translator = 'Translator',
}

export interface TypedTextResource {
  uuid: string;
  word_count: string;
  word_count_auto: string;
  word_count_manual: string;
  page_count: string | undefined;
  filetype: string;
  type: string;
  context: string;
  filename: string;
  text?: string;
  isExcelFile?: boolean;
  excelRadioValue?: string;
}

export interface Resource {
  uuid: string;
  word_count: string;
  word_count_auto: string;
  word_count_manual: string;
  page_count: string | undefined;
  filetype: string;
  type: string;
  size: number;
  errors?: any;
  context: string;
  filename: string;
  text?: string;
  isExcelFile?: boolean;
  excelRadioValue?: string;
}

export enum WizardProjectType {
  TRANSLATION = 'translation',
  TRANSCRIPTION = 'transcription',
  PROOFREADING = 'proofreading',
}

export interface UpdatedTextResult {
  uuid: string;
}

export interface WizardFinalizeResult {
  cart_key: string;
}
