import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface SidebarState {
  sidebarToggle: boolean;
}

const initialState: SidebarState = {
  sidebarToggle: true,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setToggleSidebar(state, {payload}: PayloadAction<boolean>) {
      state.sidebarToggle = payload;
    },
  },
});

export const {setToggleSidebar} = sidebarSlice.actions;

export default sidebarSlice.reducer;
