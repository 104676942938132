import {AxiosResponse} from 'axios';
import queryString from 'query-string';

import core from './core';
import {OptionsProps} from '../components/BaseComponents/BaseSingleSelect';
import {
  IPrePostProcessingSettingsResponse,
  IRegistryDynamicProjectResponse,
  LanguageResponse,
  PriceConfigResponse,
  TargetLanguagesResponse,
} from '../components/Wizard/WizardViewUtils';
import {FileData, IPriceQuote} from '../lib/store/stores/wizard';
import {ApiResponse, UpdatedTextResult, WizardFinalizeResult, WizardProjectType} from '../types/Wizard/types';

export const fetchPriceQuote = (data: FormData, signal: AbortSignal): Promise<IPriceQuote> =>
  core
    .post('/api/2/wizard/pricequote', data, {signal})
    .then((response: AxiosResponse<ApiResponse<IPriceQuote>>) => response.data.results);

export const fetchTopics = (projectType: WizardProjectType): Promise<OptionsProps[]> => {
  const params = new URLSearchParams({
    service_type: projectType,
  });
  return core.get(`/bff/topics`, {params}).then((response: AxiosResponse<OptionsProps[]>) => response.data);
};

export const fetchLanguages = (): Promise<ApiResponse<LanguageResponse>> =>
  core.get('/registry/get?data=Language').then((response) => response.data);

export const fetchPriceConfig = (): Promise<ApiResponse<PriceConfigResponse>> =>
  core.get('/registry/get?data=Price').then((response) => response.data);

export const fetchTargetLanguages = (slug: string): Promise<ApiResponse<TargetLanguagesResponse>> =>
  core.get(`/api/2/wizard/getavailablelanguagesBySlug?slug=${slug}`).then((response) => response.data);

export const postUploadedFile = (data: FormData): Promise<AxiosResponse<ApiResponse<FileData[]>>> =>
  core.post('/ajax/filepicker/fileUploaded', data);

export const postUpdatedText = (data: FormData): Promise<AxiosResponse<ApiResponse<UpdatedTextResult>>> =>
  core.post('/wizard/updateText', data);

export const finalizeOrder = (fd: FormData): Promise<AxiosResponse<ApiResponse<WizardFinalizeResult>>> =>
  core.post('/api/2/wizard/finalize', fd);

export const getIsPrePostProcessingSettings = (): Promise<
  AxiosResponse<ApiResponse<IPrePostProcessingSettingsResponse>>
> => core.get('/bff/prePostProcessingSettings');

export const getRegistryDynamicProjects = (): Promise<AxiosResponse<ApiResponse<IRegistryDynamicProjectResponse>>> =>
  core.get('/bff/registryDynamicProjects');

export const updateRegisterInfo = (CSRFToken: string, params: any) =>
  core.post(
    `/userService/updateRegisterInfo?CSRFToken=${CSRFToken}`,
    queryString.stringify(params, {
      skipNull: true,
    })
  );
