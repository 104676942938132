import {useCallback, useState} from 'react';

import useUserDataApi from '@/src/hooks/useUserDataApi';
import {AdminSubmarineData} from '@/src/types/Wizard/types';

const useUserData = () => {
  const [isAdminActionsOpen, setAdminActionsOpen] = useState(false);
  const [subX, setSubmarineX] = useState(300);
  const [subY, setSubmarineY] = useState(600);

  const setSubLocation = (x: number, y: number) => {
    setSubmarineX(x);
    setSubmarineY(y);
  };

  const [dimensions, setDimensions] = useState({
    minWidth: '350px',
    minHeight: '450px',
  });

  const closeAdminActions = useCallback(() => setAdminActionsOpen(false), []);
  const openAdminActions = useCallback(() => setAdminActionsOpen(true), []);

  const adminSubmarine: AdminSubmarineData = {
    isAdminActionsOpen,
    closeAdminActions,
    openAdminActions,
    subX,
    subY,
    setSubLocation,
    dimensions,
    setDimensions,
  };

  const {
    updateUserData,
    createdUserId,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    needTwoFactorAuth,
    errorMessages,
    setErrorMessages,
    isFetchingAuth,
    handleRedirect,
  } = useUserDataApi();

  return {
    adminSubmarine,
    updateUserData,
    createdUserId,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    needTwoFactorAuth,
    errorMessages,
    setErrorMessages,
    isFetchingAuth,
    handleRedirect,
  };
};

export default useUserData;
