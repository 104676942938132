import {useCallback} from 'react';

import {sendGTMEvent} from '@next/third-parties/google';

import {useGetUserDataQuery} from '@/src/lib/store/stores/api';

export interface ITrackingState {
  gtmTrack: (props: GtmEventInterface) => void;
}

export interface GtmEventInterface {
  event?: string;
  [propName: string]: string | undefined | VoidFunction | number | string[] | unknown | null | boolean;
}

const useTracking = (): ITrackingState => {
  const {data: userData} = useGetUserDataQuery();

  const gtmTrack = useCallback(
    (props: GtmEventInterface): void => {
      const vtc = document.cookie.match('_vtc=([^;]*)');

      const eventData = {
        user_id: userData?.id,
        vtc_key: vtc && vtc[1],
        ...props,
      };

      sendGTMEvent(eventData);
    },
    [userData?.id]
  );

  return {gtmTrack};
};

export default useTracking;
