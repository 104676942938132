import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {initialCartData} from '@/src/components/ThankYou/ThankYouConsts';
import {CartProjectsProps} from '@/src/interfaces/types/thankyou.types';

interface ThankYouCartState {
  data: CartProjectsProps | null;
  errorMessage: string | null;
}

const initialState: ThankYouCartState = {
  data: initialCartData,
  errorMessage: null,
};

const thankYouCartSlice = createSlice({
  name: 'thankYouCart',
  initialState,
  reducers: {
    setCartData(state, {payload}: PayloadAction<ThankYouCartState['data']>) {
      state.data = payload;
    },
    setErrorMessage(state, {payload}: PayloadAction<ThankYouCartState['errorMessage']>) {
      state.errorMessage = payload;
    },
  },
});

export const {setCartData, setErrorMessage} = thankYouCartSlice.actions;

export default thankYouCartSlice.reducer;
