import queryString from 'query-string';

import {LoginRequestParams, PostSignupParams} from '@/src/types/Wizard/types';

import core from './core';

export const deleteSNData = (): Promise<never> => core.delete('api/2/account/sn-info');

export const loginUser = (params: LoginRequestParams, config: any): Promise<any> =>
  core.post(config.authPath, params, {
    baseURL: process.env.REACT_APP_AUTH_URL ?? config.authServer,
    withCredentials: true,
  });

export const signupUser = (params: PostSignupParams): any =>
  core.post(
    '/userService/addAccountInfo',
    queryString.stringify(params, {
      skipNull: true,
    })
  );

export const logout = (formData: any) => core.post('/ajax/user/logout', formData);

export const postResetPassword = (email: string, CSRFToken: string) =>
  core.post(
    '/api/2/account/auth/password/resetPasswordToken',
    queryString.stringify(
      {email, CSRFToken},
      {
        skipNull: true,
      }
    )
  );

export interface CountryInfo {
  CountryId: number;
  Country: string;
  Code: string;
  ContinentCode: string;
  PhoneNumber: string | null;
  DeletedAt: string | null;
}

interface CountriesResponse {
  list: CountryInfo[];
  countryByIp: string;
}

export const getCountriesInfo = (): Promise<{data: CountriesResponse} | null> =>
  core
    .get<CountriesResponse>('/userService/getCountriesInfo')
    .then((data) => data)
    .catch((err) => {
      console.error(err);
      return null;
    });

export const getUserData = async (): Promise<any> => {
  let headers = {};

  if (typeof window === 'undefined') {
    const {cookies} = await import('next/headers');

    const cookieStore = cookies();
    const sessionId = cookieStore.get('OHTSESS')?.value;

    headers = {
      Cookie: `OHTSESS=${sessionId}`,
    };
  }

  return core.get('/bff/current-user', {
    headers,
  });
};

export const notifyHubspotRegistration = (config: any, hubspotData: any): Promise<any> => {
  const defaults = {
    ...{
      firstname: '',
      lastname: '',
      email: '',
      industry_custom: '',
      company: '',
      business_personal__c: '',
      oht_user_id__c: '',
      ip_address__c: '',
    },
    ...hubspotData,
  };
  if (typeof document !== undefined) {
    const cookieParts = ('; ' + document.cookie).split('; hubspotutk=');
    const payload = {
      submittedAt: Date.now(),
      fields: Object.keys(defaults).map((key) => ({
        name: key,
        value: defaults[key],
      })),
      context: {
        hutk: cookieParts.length === 2 ? cookieParts.pop()?.split(';').shift() : '',
        pageUri: document.location.href,
        pageName: document.title,
        ipAddress: defaults.ip_address__c,
      },
    };
    return core.post(config.hubspotApi + config.hubspotRegisterFormUUid, payload).then(({data}) => data);
  }

  return Promise.reject(new Error('Document is undefined. Cannot proceed with HubSpot registration.'));
};
