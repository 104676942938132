import {FC, useState} from 'react';

import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import ConstTheme from '@/theme';

import Consts from '../AuthModalConsts';
import AuthSocial from '../AuthSocial';
import Title from '../Title';
import Footer from './components/Footer';

const {LOGIN_TITLE} = Consts;

const Form = dynamic(() => import('./components/Form'), {ssr: false});

interface Props {
  isOpen: boolean;
  handleCloseModal: VoidFunction;
  handleOpenModal: (type: ModalMode) => void;
  needSocialButtons?: boolean;
  redirectTo?: string;
}

const LoginModal: FC<Props> = ({isOpen, handleCloseModal, handleOpenModal, needSocialButtons = false, redirectTo}) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useUserData();

  const [email, setCurrentEmail] = useState<string>('');

  return (
    <DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={isOpen}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <Title id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {LOGIN_TITLE}
      </Title>
      <ModalContent>
        {needSocialButtons && <AuthSocial />}
        <Form emailUpdatedHandler={setCurrentEmail} emailParam={email} redirectTo={redirectTo} />
        <Footer handleOpenModal={handleOpenModal} email={email} />
      </ModalContent>
    </DialogItem>
  );
};

const ModalContent = styled(MuiDialogContent)`
  padding: 8px 30px;

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

const DialogItem = styled(Dialog)`
  &.MuiDialog-paper {
    overflow: visible;
    max-width: 560px;
    border-radius: 0.75rem;

    @media ${({theme}) => theme.breakpoints.maxSm} {
      margin: 10px;
      width: 100%;
    }
  }
`;
export default LoginModal;
